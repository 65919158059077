.chart{
  flex: 4;
  box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
-webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
-moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
padding: 10px;
color: "gray";


.title {
  margin-bottom: 20px;
}

.chartGrid {
  stroke:rgb(253, 227, 217);
}
}