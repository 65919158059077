.roomData {
  height: 600px;
  padding: 20px;
  align-items: center;
  justify-content: center;

  .cellWithImg {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .cellImg {
      widows: 32px;
      height: 32px;
      object-fit: cover;
      margin-right: 20px;
    }
  }


  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.available{
      color: green ;
      font-weight: bold;
      background-color: rgba(0, 128, 0, 0.128);
    }

    &.maintainance{
    color: crimson;
    font-weight: bold;
    background-color: rgba(220, 20, 60, 0.144);
    }

    &.no-vacancy{
      color: goldenrod;
      font-weight: bold;
      background-color: rgba(218, 165, 32, 0.151);
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: deepskyblue;
      border: 1px dotted rgba(0, 191, 255, 0.482);
      cursor: pointer;
    }

    .deleteButton{
      padding: 2px 5px;
      border-radius: 5px;
      color: red;
      border: 1px dotted rgba(255, 0, 0, 0.326);
      cursor: pointer;
    }

      .editButton{
      padding: 2px 5px;
      border-radius: 5px;
      color: goldenrod;
      border: 1px dotted rgba(218, 165, 32, 0.274);
      cursor: pointer;
    }
  }


}