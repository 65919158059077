
.widget{
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
-webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
-moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
border-radius: 10px;
height: 100px;

.left, .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-weight: bold;
    font-size: 14px;
    color: orange;
  }

  .counter {
    font-size: 28px;
    font-weight: 300;
  }

  .link {
    width: max-content;
    font-size:12px;
    border-bottom: 1px solid grey;
  }
  .percentage {
    display: flex;
    align-items: center;
    font-size: 14px;

    &.positive{
      color: green;
    }
    &.negative{
      color: red;
    }
  }
  .icon {
    font-size: 18px;
    padding: 5px;
    // background-color: rgba(255, 86, 8, 0.541);
    border-radius: 5px;
    align-self: flex-end;
  }
}
}