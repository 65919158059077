.datatable{
  height: 600px; 
  padding: 20px;




  .cellWithImg{
    display: flex;
    align-items: center;


    .cellImg{
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }


  .cellWithStatus{
       padding: 5px;
    border-radius: 5px;
 
    &.active{
      color: green ;
      font-weight: bold;
      background-color: rgba(0, 128, 0, 0.128);
    }

     &.pending{
      color: goldenrod;
           font-weight: bold;
      background-color: rgba(218, 165, 32, 0.151);
    }

     &.passive{
      color: crimson;
           font-weight: bold;
      background-color: rgba(220, 20, 60, 0.144);
    }
  }

  .cellAction{
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton{
      padding: 2px 5px;
      border-radius: 5px;
      color:deepskyblue ;
      border: 1px dotted rgba(0, 191, 255, 0.482);
      cursor: pointer;
    }

     .deleteButton{
      padding: 2px 5px;
      border-radius: 5px;
      color:red;
      border: 1px dotted rgba(255, 0, 0, 0.326);
      cursor: pointer;
    }
  }



}