.list{
  display: flex;
   width: 100%;

  .listContainer{
  flex: 6;

  .listTitle{
display: flex;
align-items: center;
justify-content: space-around;
font-weight: 500;
color: #000;
margin-bottom: 15px;
margin-top: 15px;
}
 
    .addNewData{
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;

   

    .addText{
      flex: 3;
    }
  }

  }
}