
.home{
      display: flex;
      width: 100%;

    .homeContainer{
    flex: 6;

    .widgets, .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts{
      padding: 5px 20px;
    }

    .listContainer {
    box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    padding: 20px;
    margin: 10px 20px;
    }
    
    .listTitle{
      font-weight: 500;
      color: #000;
      margin-bottom: 15px;
    }

  }

}