.addRoom{
  width: 100%;
  display: flex;

  .addContainer{
    flex: 6;

    .top{
    box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    padding: 10px;
    margin: 20px;
    display: flex;

     .title{
      color:gray;
      font-size: 20px;

    }  
      }

       .bottom{
    box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 50px;

  


       button{
          width: 150px;
          padding: 10px;
          border: none;
          background-color: blanchedalmond;
          color: #000;
          cursor: pointer;
          margin-top: 10px;
        }
    }
  }
}