
.table{

  .cellWrapper {
    display: flex;
    align-items: center;

    .image{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }

  }

  .status{
    padding: 5px;
    border-radius: 5px;

    &.Checked-in{
      color: green ;
      font-weight: bold;
      background-color: rgba(0, 128, 0, 0.128);
    }

     &.Checked-out{
      color: goldenrod;
           font-weight: bold;
      background-color: rgba(218, 165, 32, 0.151);
    }

     &.Pending{
      color: cornflowerblue;
           font-weight: bold;
      background-color: rgba(100, 148, 237, 0.203);
    }

      &.Canceled{
      color: red;
           font-weight: bold;
      background-color: rgba(255, 0, 0, 0.13);
    }
  }

}