.new{
  width: 100%;
  display: flex;

  .newContainer{
    flex: 6;

    .top, 
    .bottom{
       box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    padding: 10px;
    margin: 20px;
    display: flex;

    .title{
      color: lightgray;
      font-size: 20px;

    }

    .left{
      flex: 1;
      text-align: center;

      img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .right{
      flex: 2;

      form{
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: space-around;

        .formInput{
          width: 40%;

          label{
            display: flex;
            align-items: center;
            gap: 10px;

            .icon{
              cursor: pointer;
            }
          }


           input{
          width: 100%;
          padding: 5px;
          border: none;
          border-bottom: 1px solid gray;
        }
        }

        button{
          width: 150px;
          padding: 10px;
          border: none;
          background-color: blanchedalmond;
          color: #000;
          cursor: pointer;
          margin-top: 10px;
        }
       
      }
    }
    }
  }
}