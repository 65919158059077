.sidebar{
  flex: 1;
 border-right: 0.5px solid rgb(230, 227, 227);
 min-height: 100vh;
 background-color: #ffffff;


  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;

    .logo{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;

  
    }
   
      .logoText{
      font-size: 10px;
      font-weight: bold;
    }
  }

  hr{
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 10px;

    ul{
      list-style: none;
      margin: 0;
      padding: 0;

      .title{
        font-size: 10px;
        font-weight: bold;
        color: #F15412;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover{
          background-color: #ffc59a56;
        }
        .icon{
          font-size: 17px;
          color:#F15412;
        }
        
        span {
          font-size: 13px;
          font-weight: 600;
          color:#000000;
          margin-left: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;


    .colorOption{
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #F15412;
      cursor: pointer;
      margin: 5px;


      &:nth-child(1){
        background-color: whitesmoke;
      }

      &:nth-child(2){
        background-color: #333;
      }
    }

  }
}