.room{
  display: flex;
  width: 100%;

  .roomListContainer{
    flex: 6;

    
  .listTitle{
display: flex;
align-items: center;
justify-content: space-around;
font-weight: 500;
color: #000;
margin-bottom: 15px;
margin-top: 15px;

.buttonLink{
text-decoration: none!important;

     .button{
          width: 150px;
          padding: 10px;
          border: none;
          background-color: orangered;
          color: #fff;
          text-align: center;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
          
   }
}


}
  }
}