.booking{
  display: flex;
  width: 100%;

  .bookingContainer{
    flex: 6;

.listTitle{
display: flex;
align-items: center;
justify-content: space-around;
font-weight: 500;
color: #000;
margin-bottom: 15px;
margin-top: 15px;
}

  }

  .listContainer {
    box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    -moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
    padding: 20px;
    margin: 10px 20px;
    }


}