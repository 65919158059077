.single {
  display: flex;
  width: 100%;

  .singleContainer{
    flex: 6;

    .top{
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
        -webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
        -moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
        padding: 20px;
        position: relative;

        .editButton{
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: orange;
          background-color: rgba(255, 166, 0, 0.087);
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .item{
          display: flex;
          gap: 20px;

          .itemImg{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;

          }

          .details{
            .itemTitle{
              margin-bottom: 10px;
              color: #000;
            }

            .detailItem{
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey{
                font-weight: bold;
                color: gray;
                margin-right: 5px;
                font-size: 10px;
              }

              .itemValue{
                font-weight: 300px;
                font-size: 10px;

              }
            }
          }
        }

      }

      .right {
        flex: 2;
      }
    }
     .bottom{
      box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
        -webkit-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
        -moz-box-shadow: 15px 12px 42px -6px rgba(140,120,111,0.79);
        padding: 20px;
        margin: 10px 20px;
  }
   .title{
    font-size: 16px;
    color: lightgray;
    margin-bottom: 20px ;
  }
  }
}